<template lang="pug">
.wrap
	LayoutPaymentFailed(v-if="user?.payment_failed")
	VitePwaManifest
	canvas.layout-canvas(ref="layoutCanvas" :style="{ background: linearBackground }")
	LayoutNav(v-if="settings" :story="settings")
	LayoutPlaceholderNav(v-else)

	.page
		.account
			.container.section
				.tabs.no-bottom-border.is-flex-wrap-nowrap
					.tabs-items
						NuxtLink.tab-item(:to="localePath('/account/profile/')" :class="{ 'is-active': activeTab === 'account' }")
							SingaIcon(icon="person" size="24")
							span {{ t('account.title') }}
						template(v-if="legacyPayments")
							a.tab-item(:href="`${config.public.SINGA_RESOLVE_PREFIX}/account/billing/?lang=${locale}`" rel="noopener")
								SingaIcon(icon="card")
								span {{t('subscription.subscription.title') }}
						template(v-else)
							a.tab-item(:href="`${config.public.SINGA_RESOLVE_PREFIX}/account/?lang=${locale}`" rel="noopener")
								SingaIcon(icon="card")
								span {{t('subscription.subscription.title') }}
						NuxtLink.tab-item(:to="localePath('/account/settings/')" :class="{ 'is-active': activeTab === 'settings' }")
							SingaIcon(icon="musical-notes")
							span {{ t('account.settings.title') }}
					.tab-content
						ClientOnly
							slot(keep-alive)
	LayoutSingaFooter.footer(v-if="settings" :story="settings")
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useQueueStore } from '~~/pinia/queueStore'
import { useSecondScreenStore } from '~~/pinia/secondScreen/secondScreenStore'
import { useUserStore } from '~~/pinia/userStore'

const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const localePath = useLocalePath()
const { t } = useI18n()
const { locale } = useI18n()
const layoutCanvas: any = ref(null)
const customColor = ref('#12B961')
const route = useRoute()
const setLang = (language: any) => {
	useHead({
		htmlAttrs: {
			lang: language
		}
	})
}
const config = useRuntimeConfig()
const legacyPayments = config.public.legacyPayments
// Fetch the settings
const { data: settings } = useNuxtData('settings-' + locale.value)
const { $preview } = useNuxtApp()
const token = config.public.storyblokAccessToken
const version = $preview ? 'draft' : 'published'

// Fetch the settings
await useAsyncData(
	'settings-' + locale.value,
	async () => {
		try {
			const defUrl = `https://api.storyblok.com/v2/cdn/stories/settings?&token=${token}&language=${locale.value}&cv=0&resolve_links=url&version=${version}`
			const response: any = await $fetch(defUrl)

			return response.story.content
		} catch (fallbackError) {
			console.error('Settings fetch failed:', fallbackError)
			return {} // Return an empty object or a default structure
		}
	},
	{
		watch: [locale],
		deep: true
	}
)
const linearBackground = computed(() => `linear-gradient(${customColor.value}, black)`)

watch(locale, (value) => {
	setLang(value)
})

const activeTab = ref('')
const setActiveTab = (path: string) => {
	if (path.includes('account-profile') || path.includes('account___')) {
		activeTab.value = 'account'
	} else if (path.includes('account-billing')) {
		activeTab.value = 'billing'
	} else if (path.includes('account-settings')) {
		activeTab.value = 'settings'
	}
}
watch(() => route.path, () => {
	setActiveTab(route.name as string)
}, { immediate: true })

onMounted(() => {
	setLang(locale)
	const queueStore = useQueueStore()
	const secondScreenStore = useSecondScreenStore()
	queueStore.$hydrate({ runHooks: false })
	// queueStore.nullifySongPlaying()
	window.onbeforeunload = () => {
		if (secondScreenStore.isSecondScreenActive) {
			secondScreenStore.closeConnection()
		}
	}
	useEventOn('layoutGradient:applyColor', (color) => {
		customColor.value = color
	})

	useEventOn('layoutGradient:resetDefault', () => { customColor.value = '#12B961' })
})

onUnmounted(() => {
	useEventOff('layoutGradient:applyColor')
	useEventOff('layoutGradient:resetDefault')
})
</script>

<style lang="sass" scoped>
.layout-canvas
	background: linear-gradient(#052E18, black)
	height: 100svh
	width: 100%
	position: absolute
	opacity: 0.1
html
	background-color: black
.wrap
	min-height: 100vh
	display: flex
	flex-direction: column
	background-size: cover
	background-position: center
.page
	flex: 1
	position: relative
	overflow: hidden
	min-height: 100vh
.footer
	margin-top: auto
.relative-wrapper
	position: relative
.tabs-items
	display: flex
	gap: $spacing-8
	flex-wrap: wrap
	@media (min-width: 850px)
		min-width: 220px
		flex-direction: column
	@media (min-width: $desktop)
		min-width: 264px
.tab-item
	display: flex
	align-items: center
	justify-content: flex-start
	padding: $spacing-8 $spacing-16
	border-radius: $radius-default
	background-color: $transparent-white-16
	border: none
	span
		@include font(basier, medium)
	&:not(.is-active)
		color: $color-grey-40
		&:hover
			background-color: $transparent-white-24
	&.is-active
		color: $primary
	@include fontSize(xs)
	@media (min-width: 850px)
		@include fontSize(s)
		min-height: 56px
		&:not(.is-active)
			color: $color-grey-30
			background-color: transparent

		border-radius: $radius-default
		border: none
		background-color: $transparent-white-16
		span
			@include font(basier, medium)
		&:not(.is-active)
			color: $color-grey-40
			&:hover
				background-color: $transparent-white-8
		&.is-active
			color: $primary
	span
		margin-left: $spacing-8
	@media (min-width: 850px)
		max-width: 220px
	@media (min-width: $desktop)
		max-width: 264px
.account
	padding: $spacing-40 0
.account-body
	max-width: 800px
	margin: 0 auto
.tab-content
	width: 100%
.tabs
	gap: $spacing-32
	flex-direction: column
	:deep(.icon)
		margin: 0
	@media (min-width: 850px)
		flex-direction: row
.icon-wrap
	width: 16px
	height: 16px
</style>
